<app-search></app-search>
<div class="pt-2 container">
  <div class="row">
    <div class="col-xs-12 col-md-12 mb-4"><h2><b>{{"Guía de compra." | translate}}</b></h2></div>
    <div class="col-xs-12 col-md-6">
      <p><b>{{"Te damos la bienvenida" | translate}}</b></p>
      <p>{{"Gracias por interesarte en nuestra marca. En The wood tailor es una e-commerce de decoración del hogar, donde trabajamos con productos hechos a mano en España y con materiales sostenibles. Nuestro objetivo es que personas como tú, amante de la decoración, de lo natural y el medio ambiente, pueda tener un hogar con personalidad y acogedor. " | translate}}</p>
      <p>{{"En esta pequeña guía de compra te mostraremos algunos tips para que puedas hacer de esta experiencia de compra, una experiencia única e inolvidable. Queremos guiarte en este proceso para que puedas decorar cualquier estancia con productos locales y de proximidad, de calidad y en tendencia." | translate}}</p>


    </div>
    <div class="col-xs-12 col-md-6">
      <p><b>{{"¿Qué muebles del hogar se pueden comprar?" | translate}}</b></p>
      <p>{{"Inspírate y decora tu hogar. Consigue que tu casa sea un espacio acogedor en el que poder relajarte, apostando por productos handmade hechos en España y eco-friendly. Escoge los materiales, colores y formas que más te gusten y no te pierdas nuestros productos en tendencia. Somos amantes de la decoración y continuamente estamos en busca de nuevas tendencias. En nuestra página web podrás encontrar seis secciones diferentes pero que a la vez, guardan mucha relación: dormitorio, salón-comedor, decoración de pared, accesorios, textil e iluminación. En ellas podrás encontrar productos de todo tipo como cabeceros, mesas de comedor, espejos, láminas, cojines, lámparas, entre muchos otros." | translate}}</p>
      <p>{{"No podemos olvidarnos de nuestra sección The wood tailor, en la cual hemos seleccionado nuestros productos favoritos para que puedas decorar tu hogar al puro estilo slow-deco. Productos hechos a mano en España y sostenibles, que realiza el equipo con mucho cariño y cuidado al detalle." | translate}}</p>
      <p>{{"Por último, podrás encontrar la sección de New in* donde podrás descubrir las últimas novedades. Cada semana incorporamos nuevos productos siguiendo las tendencias más actuales." | translate}}</p>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12">
      <img class="img-fluid" src="../../../../assets/img/Compra-min.png" alt="instrucciones">
    </div>

  </div>
  <div class="m-4"></div>
  <div class="row">
    <div class="col-xs-12 col-md-6">
      <p><b>{{"¿Qué características debe tener un producto para tu hogar?" | translate}}</b></p>
      <p>{{"Calidad. Nuestros muebles tienen ese toque especial que tanto gusta por estar elaborados con materiales naturales y es que al tratarse de materiales sostenibles, la calidad determina la gran durabilidad y comodidad que tienen." | translate}}</p>
      <p>{{"Durabilidad. Este aspecto es importante para que puedan ser muebles durables en el tiempo y puedas tenerlos contigo muchos años. Como se comenta en el apartado anterior, el hecho de utilizar materiales naturales como la madera o el hierro, hará que puedan durar todo lo que desees." | translate}}</p>
      <p>{{"Resistencia. Los muebles constantemente están expuestos a frío, calor, humedad o polvo. Por eso, en cada ficha de producto te damos una serie de consejos para que puedas mantener tus productos de la manera más cómoda y fácil posible." | translate}}</p>
      <p>{{"Comodidad. Cualquier mueble es importante que sea cómodo y útil para lograr que tu hogar sea confortable. Es por eso que la comodidad es un punto que siempre tenemos en cuenta a la hora de diseñarlos." | translate}}</p>


    </div>
    <div class="col-xs-12 col-md-6">
      <p><b>{{"¿Cuánto tardaré en tener mi pedido en casa?" | translate}}</b></p>
      <p>{{"Nuestros envíos son a partir de 24 horas. Podrás ver el plazo de entrega en cada ficha de producto así como una vez realizado el pedido, podrás consultar el estado dentro de tu cuenta. Dispones de dos tipos de envíos en nuestra página web, el envío standard y el envío plus." | translate}}</p>
      <p>{{"En cuanto a los gastos de envío, podrás ver los gastos de envío de tu pedido una vez tengas todos los productos añadidos al carrito y antes de finalizar la compra, en el momento que añades la dirección de entrega. Se calcula en función del volumen, peso e importe de los productos además de a dónde sea el lugar de entrega." | translate}}</p>
      <p>{{"Si quieres recibir tu pedido más tarde, podrás añadir esta observación en “comentarios” para que puedas indicar el día y la preferencia horaria que mejor te convenga. Así mismo, si no estás en casa cuando se entregue tu pedido, el mismo transportista se pondrá en contacto para acordar una segunda fecha de entrega." | translate}}</p>
    </div>
  </div>

</div>


