<div class="buscador" id="buscador">

  <div>
    <p><b>{{ "Mira o descárgate las instrucciones" | translate }}</b></p>
    <p>{{ "Introduce el nombre o referencia del producto:" | translate }}</p>
  </div>

  <div class="row pt-5" id="barrabuscar">
    <div class="col" id="buscar">
      <img src="../../../assets/img/Very-Basic-Search-icon.png">
      <input #termino type="text" (keyup)="buscar(termino.value)" class="form-control search_button" id="boton-buscar"
        placeholder="{{ 'Buscar' | translate }}" ng-model="buscarlength">
    </div>
  </div>
</div>


<div *ngIf="emptyInput">

  <p style="text-align: center;"> {{ "Introduce 4 o más caracteres" | translate }}
  </p>

</div>

<div *ngIf="emptyProducts">

  <p style="text-align: center;">
    {{ "No se han encontrado productos con este nombre o referencia" | translate }}
  </p>

</div>

<!--<div class="mira_descarga" *ngIf="fullInput">
    <h1 style="text-align: center;">Mira o descarga las instrucciones.</h1>
</div>-->


<app-loading class="m-5" *ngIf="loading"></app-loading>

<app-product-cell *ngIf="!loading" [items]="productos"></app-product-cell>


<div *ngIf="router.url === '/'" class="pl-3 pr-3 pt-2 guias">
  <h2> <strong>{{ "Te damos la bienvenida" | translate }}.</strong></h2>
  <div class="card-deck">
    <div class="card p-2" routerLink="/guia-instrucciones">

      <div *ngIf="this.mobile">
        <img class="img-fluid" src="../../../assets/img/Instrucciones-movile.jpg" alt="Card image cap">
      </div>
      <div *ngIf="!this.mobile">
        <img class="img-fluid" src="../../../assets/img/Instrucciones.jpg" alt="Card image cap">
      </div>


      <div class="card-body">
        <h5 class="card-title">{{ "Guía de instrucciones" | translate }} >></h5>
      </div>
    </div>
    <div class="card p-2" routerLink="/guia-mantenimiento">
      <div *ngIf="this.mobile">
        <img class="img-fluid" src="../../../assets/img/Mantenimiento-movile.png" alt="Card image cap">
      </div>
      <div *ngIf="!this.mobile">
        <img class="img-fluid" src="../../../assets/img/Mantenimiento-min.png" alt="Card image cap">
      </div>
      
      <div class="card-body">
        <h5 class="card-title">{{ "Guía de mantenimiento" | translate }} >></h5>
      </div>
    </div>
  </div>

</div>
