
<div class="row animated fadeIn" *ngIf="producto.nombre">
    <!-- <div class="col-2">
        <img [src]="producto.img" class="img-thumbnail ">
    </div> -->

    <div class="product_mv">
        <div class="row">
            <div class="col-xsca-12 col-md-6 product-pdf">
                <embed [src]="producto.instrucciones | domseguro" type="application/pdf" width="100%" height="720px"/>
            </div>
            <div class="col-xs-12 col-md-6 product-info">
                <h3 class="pl-4 pr-4 font-weight-bold" >{{ producto.nombre }}</h3>
            
                <!-- <div class="product-description">{{producto.descripcion_corta}}</div> -->
                <p class="pl-4 pr-4">{{"¡Montar un mueble de The wood tailor es muy fácil! Aquí te dejamos las instrucciones del mueble que has seleccionado para que puedas seguir" | translate}}</p>
                <a [href]="producto.instrucciones" class="btn btn-primary" target="_blank" style="color: #fff; background-color: #000000; border-color: #000000; border-radius: 0px; width: 100%; padding: 3%;">
                    {{"Descargar" | translate}} >>
                </a>
            </div>

        </div>
        

        
    </div>
    
</div>

