<footer>

    <div class="container">

        <div class="row">
          <table class="footer_table">

            <tbody><tr>
              <td><a href='{{"https://thewoodtailor.es/es/content/7-politica-de-cookies" | translate}}'>{{"COOKIES" | translate}}</a></td>
              <td class="footer_table_td_separator"><div class="footer_vertical_line"></div></td>
              <td><a href='{{"https://thewoodtailor.es/es/content/8-atencion-al-cliente" | translate}}'>{{"ATENCIÓN AL CLIENTE" | translate}}</a></td>
              <td class="footer_table_td_separator"><div class="footer_vertical_line"></div></td>
              <td><a href='{{"https://thewoodtailor.es/es/content/2--aviso-legal" | translate}}'>{{"AVISO LEGAL" | translate}}</a></td>
            </tr>
            <tr>

            </tr>
            <tr class="footer_last_tr">
              <td colspan="5">

                <section style="text-align:center">
                  <div>© 2023 The wood tailor</div>
                </section>

              </td>
            </tr>


          </tbody>
        </table>
        </div>

      </div>



</footer>

